import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Bredcrum from "../Bredcrum";
import Calender from "../Calender/Calender";
import MemberTable from "../Customer/MemberTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormControlLabel } from "@mui/material";
import AddTaxesModal from "./AddTaxesModal";
import AddFeesModal from "./AddFessModal";
// import { useDemoData } from "@mui/x-data-grid-generator";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const FeesColumns = [
  { name: "Fee Name", options: { filterOptions: { fullWidth: true } } },
  "Fee Type",
  "Fee Amount",
  "Fee Status",
  "",
];
const Feesdata = [
  [
    "Convenience Fee",
    "Percentage",
    "1",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
  [
    "Shipping & Handling Fee",
    "Percentage",
    "3",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];
const managementColumns = [
  { name: "Tax Name", options: { filterOptions: { fullWidth: true } } },
  "Tax Type",
  "Tax Amount",
  "Product Segment",
  "Product Sub Segment",
  "Tax Status",
  "",
];
const managementdata = [
  [
    "CGST",
    "Percentage",
    "9",
    "",
    "",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
  [
    "IGST",
    "Percentage",
    "19",
    "",
    "",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
  [
    "Consumer Tax",
    "Flat",
    "12",
    "",
    "",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

export default function TaxFees() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const ref3 = React.useRef();
  const ref2 = React.useRef();
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />

              <CardContent>
                <Grid container className="full_content">
                  <Grid container spacing={2}>
                    <Grid item md="12" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container>
                            <Grid item md="12">
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Typography mb={2} className="t-heading">
                                  Taxes
                                </Typography>
                                <Button
                                  variant="outlined"
                                  className="btn-outline-primary"
                                  onClick={() => ref2.current.log()}
                                >
                                  Add
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item md="12">
                              <MemberTable
                                columns={managementColumns}
                                options={managementOptions}
                                data={managementdata}
                              />
                            </Grid>
                          </Grid>

                          <Grid container mt={1}>
                            <Grid item md="12">
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Typography mb={2} className="t-heading">
                                  Fees
                                </Typography>
                                <Button
                                  variant="outlined"
                                  className="btn-outline-primary"
                                  onClick={() => ref3.current.log()}
                                >
                                  Add
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item md="12">
                              <MemberTable
                                columns={FeesColumns}
                                options={managementOptions}
                                data={Feesdata}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddTaxesModal ref={ref2} />
        <AddFeesModal ref={ref3} />
      </Box>
    </>
  );
}
