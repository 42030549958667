import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import upload from "../images/upload.svg";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import dal2 from "../images/dal2.jpg";
import dal3 from "../images/dal3.jpg";
import dal4 from "../images/dal4.jpg";

import status from "../images/order.svg";
import MemberTable from "../Customer/MemberTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StoreNewItemModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [edit, setEdit] = React.useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const changeEdit = () => setEdit((edit) => !edit);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleOpenEdit = () => setShow(true);
  const handleCloseAdd = () => setAddOpen(false);

  const data = [
    [
      "12/03/2023",
      "474858",
      "Accepted",
      "₹ 22890",
      "Pending",
      "Cheque",
      "12345678",
      "12/03/2023",
    ],
  ];

  const memeberColumns = [
    { name: "PO Date", options: { filterOptions: { fullWidth: true } } },
    "PO ID",
    "PO Status",
    "PO Total Invoice Amount",
    "Payment Status",
    "Transaction Type",
    "Transaction ID",
    "Transaction Date",
    {
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Select
              labelId="demo-isolation-label"
              id="isolation-select"
              className="select retaile-select"
              // onChange={handleSelectOxygen}
              // input={<OutlinedInput />}
              defaultValue="locations"
            >
              <MenuItem value="locations">Channel</MenuItem>
              <MenuItem>Download</MenuItem>
              <MenuItem>Email</MenuItem>
              <MenuItem>Email Accounting</MenuItem>
            </Select>
          );
        },
      },
    },
    {
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              onClick={() => console.log(value, tableMeta)}
              className="retail-btn"
            >
              Payment
            </Button>
          );
        },
      },
    },
  ];

  const ref4 = useRef();

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="view-more-content retail-content sni-content"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              RETAILERS DETAILS
            </Typography>
            <Box className="sub-modal-header">
              <Button
                variant="outlined"
                color="error"
                mt={2}
                mb={2}
                onClick={changeEdit}
                className="retail-btn  mr-2"
              >
                {edit ? "Edit" : "Save"}
              </Button>
              <CloseIcon onClick={handleClose} />
            </Box>
          </Box>
          <Divider></Divider>
          <Box className="modal-body">
            <Grid className="add_new_item">
              <Card>
                <CardContent className={edit ? "disable" : ""}>
                  <Typography component="h4" className="sni-title">
                    SKU ID - 454989
                  </Typography>

                  <Box className="images d-flex">
                    <Box className="position-relative">
                      <Box className="upload__img-close"></Box>
                      <Box component="img" src={dal2} />
                    </Box>

                    <Box className="position-relative">
                      <Box className="upload__img-close"></Box>
                      <Box component="img" src={dal3} />
                    </Box>

                    <Box className="position-relative">
                      <Box className="upload__img-close"></Box>
                      <Box component="img" src={dal4} />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      component="label"
                      className="btn-upload"
                    >
                      Upload image
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        disabled={edit ? true : false}
                      />
                    </Button>
                  </Box>

                  <Grid container>
                    <Grid mt={2} xs={12} md={3}>
                      <FormControl className="w-100">
                        <Typography component="label" className="small">
                          Location
                        </Typography>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="madhapur"
                          disabled={edit ? true : false}
                        >
                          <MenuItem value="madhapur">Madhapur</MenuItem>
                          <MenuItem value="visakhapatnam">
                            visakhapatnam
                          </MenuItem>
                          <MenuItem value="vijayawada">vijayawada</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container spacing={3} mt={1} p={0}>
                      <Grid item xs={12} md={4}>
                        <FormControl className="w-100">
                          <Typography component="label">Item Name</Typography>
                          <OutlinedInput
                            placeholder="Chana Dal"
                            disabled={edit ? true : false}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl className="w-100">
                          <Typography component="label">Brand</Typography>
                          <OutlinedInput
                            placeholder="Tata"
                            disabled={edit ? true : false}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box className="information-div">
                          <Box component="img" src={status} />
                          <Box>
                            <Typography component="label">Quantity</Typography>
                            <Typography>100 Kgs</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container mt={1} mb={3} className="textarea-div">
                      <Grid item xs={12} md={12}>
                        <FormControl className="w-100">
                          <Typography component="label">
                            About this product
                          </Typography>
                          <Box
                            component="textarea"
                            disabled={edit ? true : false}
                            rows={5}
                            value="Dal is a term originating in the Indian subcontinent for dried, split pulses that do not require soaking before cooking. India is the largest producer of pulses in the world. The term is also used for various soups prepared from these pulses."
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12} mt={2}>
                        <FormControl className="w-100">
                          <Typography component="label">How to use</Typography>
                          <Box
                            component="textarea"
                            disabled={edit ? true : false}
                            rows={5}
                            value="Dal is a term originating in the Indian subcontinent for dried, split pulses that do not require soaking before cooking. India is the largest producer of pulses in the world. The term is also used for various soups prepared from these pulses."
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12} mt={2}>
                        <FormControl className="w-100">
                          <Typography component="label">
                            Specifications
                          </Typography>
                          <Box
                            component="textarea"
                            disabled={edit ? true : false}
                            rows={5}
                            value="Dal is a term originating in the Indian subcontinent for dried, split pulses that do not require soaking before cooking. India is the largest producer of pulses in the world. The term is also used for various soups prepared from these pulses."
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Typography component="h4" className="sub_title" mt={2}>
                      ITEM LOCATION
                    </Typography>
                    <Grid container spacing={2} mt={1} mb={2}>
                      <Grid item xs={12} md={6}>
                        <Typography component="b" className="small">
                          Online Store
                        </Typography>
                        <FormControl className="w-100">
                          <Typography component="label">Segment</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="Segment"
                            disabled={edit ? true : false}
                          >
                            <MenuItem value="Segment">Segment</MenuItem>
                            <MenuItem value="visakhapatnam">
                              visakhapatnam
                            </MenuItem>
                            <MenuItem value="vijayawada">vijayawada</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl className="w-100 mt-15">
                          <Typography component="label">Sub-Segment</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="Segment"
                            disabled={edit ? true : false}
                          >
                            <MenuItem value="Segment">Segment</MenuItem>
                            <MenuItem value="visakhapatnam">
                              visakhapatnam
                            </MenuItem>
                            <MenuItem value="vijayawada">vijayawada</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography component="b" className="small">
                          In Store
                        </Typography>
                        <FormControl className="w-100">
                          <Typography component="label">AISLE</Typography>
                          <OutlinedInput
                            placeholder="Brand Name"
                            disabled={edit ? true : false}
                          />
                        </FormControl>
                        <FormControl className="w-100 mt-15">
                          <Typography component="label">Racks</Typography>
                          <OutlinedInput
                            placeholder="Brand Name"
                            disabled={edit ? true : false}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Typography component="h4" mt={2} className="font-bold">
                      UNIT OF MEASUREMENT
                    </Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} md={4}>
                        <FormControl className="w-100">
                          <Typography component="label">Metric</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="select"
                            disabled={edit ? true : false}
                          >
                            <MenuItem value="select">Please Select</MenuItem>
                            <MenuItem value="weight">Weight</MenuItem>
                            <MenuItem value="volume">Volume</MenuItem>
                            <MenuItem value="count">Count</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl className="w-100">
                          <Typography component="label">UOM</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="select"
                            disabled={edit ? true : false}
                          >
                            <MenuItem value="select">Please Select</MenuItem>
                            <MenuItem value="weight">Lbs</MenuItem>
                            <MenuItem value="volume">Kgs</MenuItem>
                            <MenuItem value="count">Grams</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl className="w-100">
                          <Typography component="label">No of Units</Typography>
                          <OutlinedInput disabled={edit ? true : false} />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Divider></Divider>
                    <Grid container spacing={3} mt={1} mb={1}>
                      <Typography component="h4" className="font-bold">
                        NOTIFICATION
                      </Typography>
                      <Grid item md={12}>
                        <FormControlLabel
                          disabled={edit ? true : false}
                          className="custom-checkbox"
                          control={<Checkbox />}
                          label="Perishable"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormControlLabel
                          disabled={edit ? true : false}
                          className="custom-checkbox"
                          control={<Checkbox />}
                          label="IdleStock"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormControl className="w-100 custom-fc">
                          <Typography component="label" mr={2}>
                            No of Days
                          </Typography>
                          <OutlinedInput disabled={edit ? true : false} />
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography component="label" className="small">
                          After ___ Days an Alert will be Generated Saying the
                          Item is Idle
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <FormControlLabel
                          disabled={edit ? true : false}
                          className="custom-checkbox"
                          control={<Checkbox />}
                          label="LowStock"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormControl className="w-100 custom-fc mt-15">
                          <Typography component="label" mr={2}>
                            Threshold Value
                          </Typography>
                          <OutlinedInput disabled={edit ? true : false} />
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography component="label" className="small mt-15">
                          After Stock Comes Below ___ then an Alert will be
                          Generated Saying low stock into reports
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider></Divider>
                  <Grid container mt={1}>
                    <Box className="flex-between w-100" mt={2} mb={2}>
                      <Typography className="font-bold" component="h4">
                        PRODUCT DETAILS
                      </Typography>
                      <Typography component="p" className="ag-skuid">
                        BEV-TE-FSD-FF-23LI
                      </Typography>
                      <Button
                        variant="outlined"
                        mt={2}
                        mb={2}
                        className="retail-btn"
                        mr={2}
                      >
                        Add Product
                      </Button>
                    </Box>
                    <Box className="member-table w-100">
                      <MemberTable
                        columns={memeberColumns}
                        options={memberOptions}
                        data={data}
                      />
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Box>
          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={3}
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default StoreNewItemModal;
