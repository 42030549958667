import React, { useImperativeHandle, forwardRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import test from "../images/test.jpg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import equip1 from "../images/equip1.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MySwal = withReactContent(Swal);

const EquipmentEditModal = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState(false);
  const handlePassword = () => {
    setPasswd((passwd) => !passwd);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "All Fields Are Mandatory",
    });
  };

  useImperativeHandle(ref, () => ({
    log() {
      handleOpen();
    },
  }));

  //   if(openM){
  //     handleOpen();
  //   }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="parent-modal "
      >
        <Box sx={{ ...style }} className="profile-modal equipment-edit-modal">
          <Grid container>
            <Grid item xs={12} className="profile-header p-15">
              <Typography id="parent-modal-title" component="h4">
                Edit Item Details
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container spacing={2}>
            <Box padding={5}>
              <Grid container spacing={2}>
                <Grid item md={5}>
                  <Typography component="p">SKU ID - 454989</Typography>
                  <Box
                    component="img"
                    src={equip1}
                    className="equip-img-edit"
                  ></Box>
                </Grid>
                <Grid item md={7}>
                  <Box>
                    <Grid container>
                      <Grid item md={12} mb={2}>
                        <FormControl className="form_control" fullWidth>
                          <Typography
                            component="label"
                            className="equip-label-txt"
                          >
                            Item Name
                          </Typography>
                          <OutlinedInput
                            placeholder="Last Name"
                            value="Wheel Chair"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item md={12} mb={2}>
                        <FormControl className="form_control w-100">
                          <Typography
                            component="label"
                            className="equip-label-txt"
                          >
                            Available At
                          </Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select viewmore-dpdown equip-select"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="Suppliers"
                          >
                            <MenuItem value="Suppliers">Select</MenuItem>
                            <MenuItem value="car">Vehicle</MenuItem>
                            <MenuItem value="van">Office</MenuItem>
                            <MenuItem value="van">Others</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={12} mb={2}>
                        <FormControl className="form_control" fullWidth>
                          <Typography
                            component="label"
                            className="equip-label-txt"
                          >
                            Vehicle Name
                          </Typography>
                          <OutlinedInput
                            placeholder="Last Name"
                            value="Nissan"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item md={12} mb={2}>
                        <FormControl className="form_control" fullWidth>
                          <Typography
                            component="label"
                            className="equip-label-txt"
                          >
                            Vehicle No.
                          </Typography>
                          <OutlinedInput
                            placeholder="Last Name"
                            value="VIT - 28389"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container spacing={2} mt={1}>
                <Grid item md={4}>
                  <FormControl className="form_control" fullWidth>
                    <Typography className="equip-label-txt" component="label">
                      File Upload
                    </Typography>
                    <OutlinedInput placeholder="Expiray Date" type="file" />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <FormControl className="w-100">
                    <Typography component="label">Description</Typography>
                    <Box component="textarea" rows={5} placeholder="" />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <FormControl className="w-100">
                    <Typography component="label">Instructions</Typography>
                    <Box component="textarea" rows={5} placeholder="" />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <FormControl className="w-100">
                    <Typography component="label">Specifications</Typography>
                    <Box component="textarea" rows={5} placeholder="" />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
});

export default EquipmentEditModal;
