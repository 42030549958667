import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardActions from "@mui/material/CardActions";
import SearchBar from "material-ui-search-bar";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CardMedia from "@mui/material/CardMedia";
import customers from "../images/customers.svg";
import mapmarker from "../images/map-marker.png";
import logout from "../images/logout.svg";
import p2 from "../images/p2.png";
import Swal from "sweetalert2";
import customer from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import mail from "../images/mail.svg";
import withReactContent from "sweetalert2-react-content";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import shoppingcounter from "../images/shopping-counter.png";
import MemberTable from "../Customer/MemberTable";
import addPerson from "../images/person-plus-fill.svg";
import door_delivery from "../images/door-delivery.svg";
import logo from "../images/logo-white.png";
import curb from "../images/curb.svg";
import store from "../images/store.svg";
import Tooltip from "@mui/material/Tooltip";
import info from "../images/info-circle.svg";
import clear from "../images/clear.svg";
import discount from "../images/Discount.svg";
import invoice from "../images/Invoice.svg";
import upscan from "../images/upc-scan.svg";
import walmart from "../images/Walmart.jpg";
import print from "../images/print-invoice.svg";
import edit from "../images/edit.svg";
import cross1 from "../images/cross.svg";
import ClearIcon from "../images/clear-cross.svg";
import distributorimg1 from "../images/distributor1.png";
import distributorimg2 from "../images/distributor2.png";
import distributorimg3 from "../images/distributor3.png";
import pr1 from "../images/p1.png";
import pr2 from "../images/p2.png";
import pr3 from "../images/p3.png";
import pr4 from "../images/p4.png";
import Checkbox from "@mui/material/Checkbox";
import globe from "../images/globe.png";
import cart from "../images/cart.svg";
import notification from "../images/notification.png";
import profile from "../images/user.png";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SortIcon from "@mui/icons-material/Sort";
import Slider from "./CategorySlider";
import star from "../images/star.png";
import item1 from "../images/item1.png";
import item2 from "../images/item2.png";
import item3 from "../images/item3.png";
import item4 from "../images/item4.png";
import item5 from "../images/item9.png";
import item6 from "../images/item10.png";
import item7 from "../images/item7.jpg";
import item8 from "../images/item8.jpg";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function Bazaar() {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);
  const ItemColumns = [
    "S.NO",
    "SKU ID",
    "ITEM NAME & DETAILS",
    "QTY",
    "PRICE",
    "TOTAL",
    "",
  ];

  const customerColumns = ["Customer ID", "Customer Name", "Email", "Phone"];

  const customerData = [
    ["#258974", "Akhil", "akhil.@gmail.com", "9478982317"],
    ["#258974", "Suresh", "suresh.@gmail.com", "8327641236"],
  ];

  const billColumns = [
    "S.NO",
    "Invoice Id",
    "Date/Time",
    "Bill Amount",
    "Payment Mode",
  ];

  const billData = [
    [
      1,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
    [
      2,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
  ];

  const itemData = [
    [1, "#98759", "Rice", 2, "$ 200", "$400"],
    [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
  ];

  const orderColumns = ["ORDER ID", "CITY", "DATE", "CUSTOMER", "STATUS"];

  const orderData = [
    [
      "#258974",
      "Visakhapatnam",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "Completed",
    ],
    [
      "#258974",
      "Rajahmundry",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "In Progress",
    ],
  ];

  const driverData = [];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [value, setValue] = React.useState(1);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [personName, setPersonName] = useState([]);

  const handleChangeDropdown = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Box component="main" className="main-section">
        <Grid container>
          <Grid item xs="12">
            <Box className="w-100 text-center h-50">
              <Typography component="p" textAlign={"center"}>
                Shop Wholesale online from 85,000 brands
              </Typography>
            </Box>
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />

                  <Box className="icons bredcum-icons">
                    <Box
                      component="img"
                      src={globe}
                      onClick={() => ref.current.log()}
                    />

                    <Typography paddingRight={1} component="h5" ml={1}>
                      EN-GB |
                    </Typography>

                    <ShoppingCartIcon />
                    <NotificationsIcon />

                    <PersonIcon onClick={() => ref.current.log()} />

                    {/* <Box className="p-relative">
                      <Box
                        component="img"
                        src={notification}
                        onClick={() => ref.current.log()}
                        ml={1}
                      />
                      <Box class="p-count">
                        <Typography component="p">
                          <Typography component="small">34</Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Typography component="label" ml={1}>
                      Notification
                    </Typography> */}

                    {/* <Button variant="filled" className="invite-supplier-btn">
                      INVITE SUPPLIER
                    </Button> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box className="ref-padding" paddingY={4}>
              <Grid container>
                <Grid item md={12}>
                  <Box
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    className="procurement-tabs"
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="tabs-custom"
                    >
                      <Tab
                        className="product-btn"
                        label="Product"
                        {...a11yProps(1)}
                      />
                      <Tab
                        className="distributor-btn"
                        label="Distributor"
                        {...a11yProps(0)}
                      />
                    </Tabs>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                marginTop={2}
                paddingBottom={2}
                className="border-top border-bottom filter-top-section"
              >
                <Grid item md={4}>
                  <Typography component="h1" className="category-name-head">
                    Wholesale Groceries and Vegetables
                  </Typography>
                  <Typography component="p" className="catagory-about mt-2">
                    Shop independent brands from around the world at wholesale
                    prices.
                  </Typography>
                </Grid>
                <Grid item md={8} className="offset-grid">
                  <Slider />
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={1}>
                <Grid item md={8}>
                  <Box className="filter-block-scroll">
                    <Box className="filter-icon1">
                      <ImportExportIcon />
                    </Box>
                    <Box className="filter-icon1">
                      <SortIcon />
                    </Box>
                    <Box className="filter-dropdown">
                      <FormControl>
                        <InputLabel
                          className="ps-2"
                          id="demo-multiple-checkbox-label"
                        >
                          Availability
                        </InputLabel>
                        <Select
                          className="w-100"
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleChangeDropdown}
                          input={<OutlinedInput label="Availability" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className="filter-dropdown">
                      <FormControl>
                        <InputLabel
                          className="ps-2"
                          id="demo-multiple-checkbox-label"
                        >
                          Price
                        </InputLabel>
                        <Select
                          className="w-100"
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleChangeDropdown}
                          input={<OutlinedInput label="Availability" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className="filter-dropdown">
                      <FormControl>
                        <InputLabel
                          className="ps-2"
                          id="demo-multiple-checkbox-label"
                        >
                          Product Type
                        </InputLabel>
                        <Select
                          className="w-100"
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleChangeDropdown}
                          input={<OutlinedInput label="Availability" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className="filter-dropdown">
                      <FormControl>
                        <InputLabel
                          className="ps-2"
                          id="demo-multiple-checkbox-label"
                        >
                          Pack Size
                        </InputLabel>
                        <Select
                          className="w-100"
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleChangeDropdown}
                          input={<OutlinedInput label="Availability" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className="filter-dropdown">
                      <FormControl>
                        <InputLabel
                          className="ps-2"
                          id="demo-multiple-checkbox-label"
                        >
                          Weight
                        </InputLabel>
                        <Select
                          className="w-100"
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleChangeDropdown}
                          input={<OutlinedInput label="Availability" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={personName.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box className="search-filter">
                    {/* <Typography component="h2">POS {pos}</Typography> */}
                    <SearchBar
                      className="search-bar w-100"
                      placeholder="Search Products or Brands"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {/* <Grid item md={2}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item md={12}>
                          <Typography className="m-heading">Filters</Typography>
                        </Grid>
                      </Grid>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Card>
                            <CardContent>
                              <Box display={"flex"}>
                                <Box
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">Groceries</Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                                <Box
                                  ml={1}
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">Masala</Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                              </Box>

                              <Box display={"flex"} mt={1}>
                                <Box
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">
                                    Chat Masala
                                  </Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                                <Box
                                  ml={1}
                                  className="filters-selection"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="p">Chunky</Typography>
                                  <Box component="img" src={ClearIcon} ml={1} />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Typography className="t-heading">Fruits</Typography>
                        </Grid>
                        <Grid item md={12} mt={1}>
                          <Typography className="t-heading">
                            Vegetables
                          </Typography>
                        </Grid>
                        <Grid item md={12} mt={1}>
                          <Typography className="t-heading">
                            Cleaning
                          </Typography>
                        </Grid>
                        <Grid item md={12} mt={1} mb={1}>
                          <Typography className="t-heading">
                            Cold Drinks
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Typography className="t-heading">Price</Typography>
                        </Grid>
                        <Grid item md={12}>
                          <OutlinedInput
                            type="range"
                            className="w-100"
                          ></OutlinedInput>
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                      <Grid container mt={1}>
                        <Grid item md={12}>
                          <Typography className="t-heading">Brands</Typography>
                        </Grid>
                        <Grid item md={12}>
                          <Box className="procurement-filter-brands-block">
                            <Box className="procurement-filter-brands">
                              <Checkbox {...label} />
                              <Typography className="small">Everest</Typography>
                            </Box>
                            <Box className="">
                              <Typography className="brands-value">
                                23145
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="procurement-filter-brands-block">
                            <Box className="procurement-filter-brands">
                              <Checkbox {...label} />
                              <Typography className="small">Catch</Typography>
                            </Box>
                            <Box className="">
                              <Typography className="brands-value">
                                23145
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="procurement-filter-brands-block">
                            <Box className="procurement-filter-brands">
                              <Checkbox {...label} />
                              <Typography className="small">Chunky</Typography>
                            </Box>
                            <Box className="">
                              <Typography className="brands-value">
                                23145
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="procurement-filter-brands-block">
                            <Box className="procurement-filter-brands">
                              <Checkbox {...label} />
                              <Typography className="small">Fortune</Typography>
                            </Box>
                            <Box className="">
                              <Typography className="brands-value">
                                23145
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="procurement-filter-brands-block">
                            <Box className="procurement-filter-brands">
                              <Checkbox {...label} />
                              <Typography className="small">Tata</Typography>
                            </Box>
                            <Box className="">
                              <Typography className="brands-value">
                                23145
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="procurement-filter-brands-block">
                            <Box className="procurement-filter-brands">
                              <Checkbox {...label} />
                              <Typography className="small">
                                Fun Food
                              </Typography>
                            </Box>
                            <Box className="">
                              <Typography className="brands-value">
                                23145
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid> */}
                <Grid item md={12} mt={2}>
                  <TabPanel
                    value={value}
                    index={1}
                    className="custom-shift-tab"
                  >
                    <Grid container spacing={2}>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg1}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                Sri Srinivasa Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>

                              <Grid container mt={1}>
                                <Grid
                                  item
                                  md={12}
                                  textAlign={"right"}
                                  className="card-footer"
                                >
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline card-btn-custom-position"
                                    href="/procurement/DistributorDetailsNew"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg2}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                Venkateshwara Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline "
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg3}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                Karachiwala Store
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>

                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline card-btn-custom-position"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg1}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                SKML Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline card-btn-custom-position"
                                    href="/procurement/DistributorDetails"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg2}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                N Suryanarayana Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg2}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                N Suryanarayana Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg1}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                SKML Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline card-btn-custom-position"
                                    href="/procurement/DistributorDetails"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg3}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                Karachiwala Store
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>

                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline card-btn-custom-position"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg2}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                Venkateshwara Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline "
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg1}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                Sri Srinivasa Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>

                              <Grid container mt={1}>
                                <Grid
                                  item
                                  md={12}
                                  textAlign={"right"}
                                  className="card-footer"
                                >
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline card-btn-custom-position"
                                    href="/procurement/DistributorDetailsNew"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg1}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                Sri Srinivasa Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>

                              <Grid container mt={1}>
                                <Grid
                                  item
                                  md={12}
                                  textAlign={"right"}
                                  className="card-footer"
                                >
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline card-btn-custom-position"
                                    href="/procurement/DistributorDetailsNew"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg2}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                Venkateshwara Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline "
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg3}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                Karachiwala Store
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>

                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline card-btn-custom-position"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg1}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                SKML Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline card-btn-custom-position"
                                    href="/procurement/DistributorDetails"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="distributor-box distributor-card h-100">
                          <Box className="distributor-box-image">
                            <Box
                              component="img"
                              className="distributor-img"
                              src={distributorimg2}
                            />
                          </Box>
                          <Box className="distributor-box-details">
                            <Box>
                              <Typography className="distributor-heading distributor-name">
                                N Suryanarayana Merchants
                              </Typography>
                              <Box display={"flex"} alignItems={"center"}>
                                <Box component="img" src={mapmarker} />
                                <Typography
                                  className="distributor-location"
                                  variant="p"
                                  ml={1}
                                  pb={1}
                                >
                                  Hyderabad
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className="distributor-location about-distributor"
                                  variant="p"
                                >
                                  Mr. Nihalchand wanted to try his luck in the
                                  family profession – business.
                                </Typography>
                              </Box>
                              <Grid container mt={1}>
                                <Grid item md={12} textAlign={"right"}>
                                  <Button
                                    variant="outlined"
                                    className="btn-primary-outline"
                                  >
                                    Shop Details
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                      <Grid item md={2} className="cards-custom-width">
                        <a className="title" href="/procurement/ProductViewNew">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item1}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              NEW POTATOES
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                              <Box className="border-top pt-2 product-item-merchant">
                                <a
                                  href="/procurement/DistributorDetailsNew"
                                  className="merchant-name"
                                >
                                  Sri Srinivasa Vegetables
                                </a>
                              </Box>
                            </Box>
                          </Box>
                        </a>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item2}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              BRUSSEL SPROUTS
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Fresh Fruits & Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item3}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              ROYAL GALA APPLES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Organic Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item4}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              FENNEL BULB
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                MVP Exotic Veggies
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item5}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              VINE TOMATOES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                SKML Fruits & Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item6}
                              className="item-img"
                              height="140"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              NECTARINES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Sri Srinivasa Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item5}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              VINE TOMATOES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                SKML Fruits & Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item4}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              FENNEL BULB
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                MVP Exotic Veggies
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item3}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              ROYAL GALA APPLES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Organic Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item2}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              BRUSSEL SPROUTS
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Fresh Fruits & Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item1}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              NEW POTATOES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Sri Srinivasa Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item2}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              BRUSSEL SPROUTS
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Fresh Fruits & Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item3}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              ROYAL GALA APPLES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Organic Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item4}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              FENNEL BULB
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                MVP Exotic Veggies
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item5}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              VINE TOMATOES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                SKML Fruits & Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item6}
                              className="item-img"
                              height="140"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              NECTARINES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Sri Srinivasa Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item5}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              VINE TOMATOES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                SKML Fruits & Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item4}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              FENNEL BULB
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                MVP Exotic Veggies
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item3}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              ROYAL GALA APPLES
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Organic Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={2} className="cards-custom-width">
                        <Box className="product-block">
                          <Box
                            className="product-block-inner"
                            textAlign={"center"}
                          >
                            <Box
                              component="img"
                              src={item2}
                              className="item-img"
                            />
                          </Box>
                          <Box
                            className="product-block-weight"
                            textAlign={"center"}
                          >
                            {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                          </Box>
                          <Box className="product-block-title-price">
                            <a className="title" href="#">
                              BRUSSEL SPROUTS
                            </a>
                            <div className="reviews mb-2">
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                              <Box component="img" src={star} />
                            </div>
                            <div className="price mb-2">
                              <span class="from">MRP </span>

                              <span class="amount theme-money">₹25</span>
                            </div>
                            <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Fresh Fruits & Vegetables
                              </a>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>
              </Grid>
            </Box>

            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}
