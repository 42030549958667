import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  Breadcrumbs,
  Link,
  Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardActions from "@mui/material/CardActions";
import SearchBar from "material-ui-search-bar";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import shoppingcounter from "../images/shopping-counter.png";
import logo from "../images/logo-white.png";
import GroupedButtons from "./GroupedButtons";
import globe from "../images/globe.png";
import cart from "../images/cart.svg";
import notification from "../images/notification.png";
import profile from "../images/user.png";
import back from "../images/back.png";
import SwiperSlider from "./SwiperSlider";
import store from "../images/store.png";
import delivery from "../images/delivery-time.png";
import Caurosel from "./Caurosel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import MemberTable from "../Customer/MemberTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Account() {
  const handleClick = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);
  const ItemColumns = [
    "S.NO",
    "SKU ID",
    "ITEM NAME & DETAILS",
    "QTY",
    "PRICE",
    "TOTAL",
    "",
  ];

  const customerColumns = ["Customer ID", "Customer Name", "Email", "Phone"];

  const customerData = [
    ["#258974", "Akhil", "akhil.@gmail.com", "9478982317"],
    ["#258974", "Suresh", "suresh.@gmail.com", "8327641236"],
  ];

  const billColumns = [
    "S.NO",
    "Invoice Id",
    "Date/Time",
    "Bill Amount",
    "Payment Mode",
  ];

  const billData = [
    [
      1,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
    [
      2,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
  ];

  const itemData = [
    [1, "#98759", "Rice", 2, "$ 200", "$400"],
    [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
  ];

  const orderColumns = ["ORDER ID", "CITY", "DATE", "CUSTOMER", "STATUS"];

  const orderData = [
    [
      "#258974",
      "Visakhapatnam",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "Completed",
    ],
    [
      "#258974",
      "Rajahmundry",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "In Progress",
    ],
  ];

  const driverData = [];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [value, setValue] = React.useState(1);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main" className="account-section">
        <Grid container>
          <Grid item xs="12">
            <Box className="w-100 text-center h-50">
              <Typography component="p">
                Shop Wholesale online from 85,000 brands
              </Typography>
            </Box>
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />
                  {/* <Box>
                    <SearchBar className="search-bar" />
                  </Box> */}
                  <Box className="icons">
                    <Box
                      component="img"
                      src={globe}
                      onClick={() => ref.current.log()}
                    />

                    <Typography component="h5" ml={1}>
                      EN-GB |
                    </Typography>
                    <Box className="p-relative">
                      <Box
                        component="img"
                        src={cart}
                        onClick={() => ref.current.log()}
                        ml={1}
                      />
                      <Box class="count">
                        <Typography component="p">
                          <Typography component="small">34</Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Typography component="label" ml={1}>
                      Cart
                    </Typography>
                    <Box className="p-relative">
                      <Box
                        component="img"
                        src={notification}
                        onClick={() => ref.current.log()}
                        ml={1}
                      />
                      <Box class="p-count">
                        <Typography component="p">
                          <Typography component="small">34</Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Typography component="label" ml={1}>
                      Notification
                    </Typography>
                    <Box
                      component="img"
                      src={profile}
                      onClick={() => ref.current.log()}
                      ml={1}
                    />
                    <Typography component="label" ml={1}>
                      Profile
                    </Typography>
                    <Button variant="filled" className="invite-supplier-btn">
                      INVITE SUPPLIER
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} textAlign={"center"}>
                <Box paddingY={5} className="border-bottom">
                  <Typography component="p" className="page-heading">
                    Account Settings
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box paddingX={5}>
              <Grid container spacing={2}>
                <Grid item md={3} className="h-100">
                  <Tabs
                    value={value}
                    onChange={handleClick}
                    aria-label="basic tabs example"
                    className="custom-tab account-tabs"
                  >
                    {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                    <Tab
                      className="tab-btn"
                      label="My Profile"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="tab-btn"
                      label="Payment Methods"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className="tab-btn"
                      label="Shipping Address"
                      {...a11yProps(2)}
                    />
                    <Tab
                      className="tab-btn"
                      label="Invoices"
                      {...a11yProps(3)}
                    />
                    <Tab
                      className="tab-btn"
                      label="My Team"
                      {...a11yProps(4)}
                    />
                    {/* <Tab
                      className="tab-btn"
                      label="My Team"
                      {...a11yProps(5)}
                    /> */}
                  </Tabs>
                </Grid>
                <Grid item md={9}>
                  <Box>
                    <TabPanel value={value} index={0}>
                      <Box className="profile-block">
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <Typography
                              component="p"
                              className="profile-heading"
                            >
                              Personal Info
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                          <Grid item md={4}>
                            <FormControl className="form_control" fullWidth>
                              <Typography
                                component="label"
                                className="input-label"
                              >
                                First Name
                              </Typography>
                              <OutlinedInput placeholder="First Name" />
                            </FormControl>
                          </Grid>
                          <Grid item md={4}>
                            <FormControl className="form_control" fullWidth>
                              <Typography
                                component="label"
                                className="input-label"
                              >
                                Last Name
                              </Typography>
                              <OutlinedInput placeholder="Last Name" />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                          <Grid item md={4}>
                            <FormControl className="form_control" fullWidth>
                              <Typography
                                component="label"
                                className="input-label"
                              >
                                Mobile Number
                              </Typography>
                              <OutlinedInput placeholder="Mobile Number" />
                            </FormControl>
                          </Grid>
                          <Grid item md={4}>
                            <Box display={"flex"}>
                              <Checkbox {...label} />
                              <Typography component="p">
                                Send me text messages with promotions and
                                special offers from Bazaar.
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                          <Grid item md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Job Description
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select viewmore-dpdown"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">Select</MenuItem>
                                <MenuItem value="car">Driver</MenuItem>
                                <MenuItem value="van">Cashier</MenuItem>
                                <MenuItem value="van">Accountant</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                          <Grid item md={4}>
                            <FormControl className="form_control" fullWidth>
                              <Typography
                                component="label"
                                className="input-label"
                              >
                                Account Email
                              </Typography>
                              <OutlinedInput placeholder="Email" />
                            </FormControl>
                          </Grid>
                          <Grid item md={4}>
                            <FormControl className="form_control" fullWidth>
                              <Typography
                                component="label"
                                className="input-label"
                              >
                                Additional Email
                              </Typography>
                              <OutlinedInput placeholder="Email" />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box className="profile-block">
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <Typography
                              component="p"
                              className="profile-heading"
                            >
                              Payment Methods
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                          <Grid item md={4}>
                            <Typography component="p" className="method-head">
                              Charged Currency : INR
                            </Typography>
                            <Button className="btn-primary">
                              Add Payment Method
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box className="profile-block">
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <Typography
                              component="p"
                              className="profile-heading"
                            >
                              Shipping Addresses
                            </Typography>
                            <Typography
                              component="p"
                              className="shipping-tagline"
                            >
                              This includes all addresses tied to your retailer
                              account
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                          <Grid item md={4}>
                            <Button className="btn-primary">
                              Add New Address
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Box className="profile-block">
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <Typography
                              component="p"
                              className="profile-heading"
                            >
                              Invoices
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1} marginLeft={0}>
                          <Box display={"flex"}>
                            <Box>
                              <Typography
                                component="p"
                                className="invoice-head"
                              >
                                Total
                              </Typography>
                              <Typography
                                component="p"
                                className="invoice-value"
                              >
                                ₹0.00
                              </Typography>
                            </Box>
                            <Box marginLeft={2}>
                              <Typography
                                component="p"
                                className="invoice-head"
                              >
                                Overdue Total
                              </Typography>
                              <Typography
                                component="p"
                                className="invoice-value"
                              >
                                ₹0.00
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                    </TabPanel>
                    {/* <TabPanel value={value} index={4}>
                      5
                    </TabPanel> */}
                    <TabPanel value={value} index={4}>
                      <Box className="profile-block">
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <Box className="d-flex">
                              <Box>
                                <Typography
                                  component="p"
                                  className="profile-heading"
                                >
                                  My Team
                                </Typography>
                                <Typography
                                  component="p"
                                  className="shipping-tagline"
                                >
                                  Add team members, update permissions, and view
                                  details for existing members here. Learn more
                                </Typography>
                              </Box>
                              <Box>
                                <Button className="btn-primary">
                                  Add Team Member
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                          <Grid item md={12}>
                            <MemberTable />
                          </Grid>
                        </Grid>
                      </Box>
                    </TabPanel>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}
