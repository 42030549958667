import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  FormControl,
  Box,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import { OutboundOutlined } from "@mui/icons-material";
import wallet from "../images/wallet.png";
import credit from "../images/credit.png";
import upi from "../images/upi.png";

export default function PayAccordion() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Delivery Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Typography component="label">Enter Location</Typography>
          <Typography component="p">
            Siripuram, VIP Road, Vishakhapatnam 530 003
          </Typography> */}
          <Box display={"flex"} flexDirection={"column"}>
            <Typography component="label">Enter Location</Typography>
            <OutlinedInput value="Siripuram, VIP Road, Vishakhapatnam 530 003" />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={2}
          >
            <Button className="btn btn-outline-primary">Change Address</Button>
            <Box className="information-div">
              <Select
                labelId="demo-isolation-label"
                id="status-select"
                className="select float-right w-100"
                // onChange={handleSelectOxygen}
                // input={<OutlinedInput />}
                defaultValue="locations"
              >
                <MenuItem value="locations">Select</MenuItem>
                <MenuItem value="visakhapatnam">Office</MenuItem>
                <MenuItem value="vijayawada">Home</MenuItem>

                <MenuItem value="kurnool">Others</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography component="h4" className="address-heading">
              SAVED ADDRESSES
            </Typography>
            <Box className="border-bottom" pb={1} mb={1}>
              <Typography component="p" className="saved-address-txt">
                Home
              </Typography>
              <Typography component="p" className="saved-address">
                Ram Villa, CBM Compound, Vishakhapatnam
              </Typography>
            </Box>
            <Box className="border-bottom" pb={1} mb={1}>
              <Typography component="p" className="saved-address-txt">
                Office
              </Typography>
              <Typography component="p" className="saved-address">
                Kukatpally, Kapila Nagar Colony, Hyderabad
              </Typography>
            </Box>
            <Box className="border-bottom" pb={1} mb={1}>
              <Typography component="p" className="saved-address-txt">
                Others
              </Typography>
              <Typography component="p" className="saved-address">
                Road No.42, Jubilee Hills, Hyderabad
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Delivery Time & Date</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl className="w-50">
            <Typography component="label">Date</Typography>
            <OutlinedInput type="date" />
          </FormControl>
          <Box mt={2}>
            <FormControl className="w-50">
              <Typography component="label">Time</Typography>
              <OutlinedInput type="Time" />
            </FormControl>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Payment</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="flex">
            <Box component="img" src={wallet} />
            <Typography ml={1}>Wallet</Typography>
          </Box>
          <Box className="flex" mt={2}>
            <Box component="img" src={credit} />
            <Typography ml={1}>Credit / Debit Card</Typography>
          </Box>
          <Box className="flex" mt={2}>
            <Box component="img" src={upi} />
            <Typography ml={1}>UPI</Typography>
          </Box>
          <Box className="flex" mt={2}>
            <Box component="img" src={wallet} />
            <Typography ml={1}>Charge to Account</Typography>
          </Box>
          <Box textAlign={"center"}>
            <Button className="btn btn-outline-primary">Submit</Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
