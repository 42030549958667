import React, { useImperativeHandle, useRef, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
  SvgIcon,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import Calender from "../Calender/Calender";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import status from "../images/last-active.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Retailers/ExpandableRowTable";
import email from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import StoreNewItemModal from "./StoreNewItemModal";
import product1 from "../images/product-1.png";
import product2 from "../images/product-2.png";
import dal from "../images/dal3.jpg";
import upload from "../images/upload.svg";
import { Label } from "@mui/icons-material";
import AddProductModal from "./AddProductModal";
import Switch from "@mui/material/Switch";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StoreNewItem() {
  const changeEdit = () => setEdit((edit) => !edit);
  const [edit, setEdit] = React.useState(true);
  const [showState, setShowState] = React.useState(false);
  const [show, setShow] = React.useState();
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setShowState(!showState);
  };
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const ref4 = useRef();

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const uomColumns = [
    "Master SKU Id",
    { name: "Metric", options: { filterOptions: { fullWidth: true } } },
    "UOM",
    "No of Units",
    "One Unit Contains",
    "Base Measurement",
  ];

  const uomData = [["#98759", "Rice", "01", "Rs. 300", "hbs", "adc"]];

  const uomoptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: true,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const memeberColumns = [
    "Batch Id",
    { name: "Vender Name", options: { filterOptions: { fullWidth: true } } },
    "PO Number",
    "PO Date",
    "Delivery Date",
    "Qty",
    "Sale Quantity",
    "Expiry Date",
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    // expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Taxes:</b> ₹ 485.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Advance:</b> ₹ 321.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Due Amount:</b> ₹ 542.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>State:</b> State
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  const data = [];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card store-item-card">
              <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link>
                    <Link underline="hover" color="inherit" href="#">
                      Product Catalogue
                    </Link>
                    <Typography color="text.primary">Add New Item</Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid>
              <CardContent className="add_new_item">
                <Card>
                  <CardContent>
                    <Typography component="h4" className="sub-title">
                      Add New Item Details
                    </Typography>

                    <Box component="img" src={upload} mt={2} />
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        component="label"
                        className="btn-upload"
                      >
                        Upload image
                        <input hidden accept="image/*" multiple type="file" />
                      </Button>
                    </Box>

                    <Grid container>
                      <Grid mt={2} xs={12} md={3}>
                        <FormControl className="w-100">
                          <Typography component="label" className="small">
                            Location
                          </Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="madhapur"
                          >
                            <MenuItem value="madhapur">Madhapur</MenuItem>
                            <MenuItem value="visakhapatnam">
                              visakhapatnam
                            </MenuItem>
                            <MenuItem value="vijayawada">vijayawada</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container spacing={3} mt={1}>
                        <Grid item xs={12} md={4}>
                          <FormControl className="w-100">
                            <Typography component="label">Item Name</Typography>
                            <OutlinedInput
                              placeholder="Item Name"
                              value="Chana Dal"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl className="w-100">
                            <Typography component="label">Brand</Typography>
                            <OutlinedInput
                              placeholder="Brand Name"
                              value="Tata"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Box className="information-div">
                            <Box component="img" src={status} />
                            <Box>
                              <Typography component="label">
                                Quantity
                              </Typography>
                              <Typography>100 Kgs</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        mt={1}
                        mb={3}
                        className="textarea-div"
                      >
                        <Grid item xs={12} md={4}>
                          <FormControl className="w-100">
                            <Typography component="label">
                              About this product
                            </Typography>
                            <Box component="textarea" rows={5} placeholder="" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl className="w-100">
                            <Typography component="label">
                              How to use
                            </Typography>
                            <Box component="textarea" rows={5} placeholder="" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl className="w-100">
                            <Typography component="label">
                              Specifications
                            </Typography>
                            <Box component="textarea" rows={5} placeholder="" />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                      <Typography component="h4" className="sub_title" mt={2}>
                        ITEM LOCATION
                      </Typography>
                      <Grid container spacing={2} mt={1} mb={2}>
                        <Grid item xs={12} md={6}>
                          <Typography component="b" className="small">
                            Online Store
                          </Typography>
                          <FormControl className="w-100">
                            <Typography component="label">Segment</Typography>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              defaultValue="Segment"
                            >
                              <MenuItem value="Segment">Segment</MenuItem>
                              <MenuItem value="visakhapatnam">
                                visakhapatnam
                              </MenuItem>
                              <MenuItem value="vijayawada">vijayawada</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl className="w-100 mt-15">
                            <Typography component="label">
                              Sub-Segment
                            </Typography>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              defaultValue="Segment"
                            >
                              <MenuItem value="Segment">Segment</MenuItem>
                              <MenuItem value="visakhapatnam">
                                visakhapatnam
                              </MenuItem>
                              <MenuItem value="vijayawada">vijayawada</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography component="b" className="small">
                            In Store
                          </Typography>
                          <FormControl className="w-100">
                            <Typography component="label">AISLE</Typography>
                            <OutlinedInput placeholder="Brand Name" />
                          </FormControl>
                          <FormControl className="w-100 mt-15">
                            <Typography component="label">Racks</Typography>
                            <OutlinedInput placeholder="Brand Name" />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                      <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6}>
                          <Typography
                            component="h4"
                            mt={2}
                            className="font-bold"
                          >
                            UNIT OF MEASUREMENT
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} textAlign={"right"}>
                          <Button
                            variant="outlined"
                            className="btn-outline-primary"
                          >
                            Add Variant
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} mb={2} mt={0}>
                        <Grid item xs={12} md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">Metric</Typography>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              defaultValue="select"
                            >
                              <MenuItem value="select">Please Select</MenuItem>
                              <MenuItem value="weight">Weight</MenuItem>
                              <MenuItem value="volume">Volume</MenuItem>
                              <MenuItem value="count">Count</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">UOM</Typography>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              defaultValue="select"
                            >
                              <MenuItem value="select">Please Select</MenuItem>
                              <MenuItem value="weight">Lbs</MenuItem>
                              <MenuItem value="volume">Kgs</MenuItem>
                              <MenuItem value="count">Grams</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">
                              No of Units
                            </Typography>
                            <OutlinedInput />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">
                              One Unit Contains
                            </Typography>
                            <OutlinedInput />
                          </FormControl>
                        </Grid>
                      </Grid>
                      {showState ? (
                        ""
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={2} sm={3} md={4}>
                            <Box className="information-div">
                              <FormControl className="w-100">
                                <Typography component="label">
                                  MSR Price
                                </Typography>
                                <OutlinedInput
                                  value=""
                                  placeholder="MSR PRice"
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={2} sm={3} md={4}>
                            <Box className="information-div">
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Cost Price
                                </Typography>
                                <OutlinedInput
                                  value=""
                                  placeholder="Cost Price"
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={2} sm={3} md={4}>
                            <Box className="information-div">
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Sale Price
                                </Typography>
                                <OutlinedInput
                                  value=""
                                  placeholder="Sale Price"
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={2} mt={1}>
                        <Grid item md={12}>
                          <MemberTable
                            columns={uomColumns}
                            options={uomoptions}
                            data={uomData}
                          />
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                      <Grid container mt={2}>
                        <Grid item md={3}>
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label="Maintain Stock"
                            onClick={handleClick}
                          />
                        </Grid>
                      </Grid>
                      {showState && (
                        <Grid container>
                          <Grid container mt={1}>
                            <Typography component="h4" className="font-bold">
                              NOTIFICATION
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item md={12}>
                                <FormControlLabel
                                  className="custom-checkbox"
                                  control={<Checkbox />}
                                  label="Perishable"
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={2} mt={1}>
                              <Grid item md={4}>
                                <FormControlLabel
                                  className="custom-checkbox"
                                  control={<Checkbox />}
                                  label="IdleStock"
                                />
                              </Grid>
                              <Grid item md={4}>
                                <FormControl className="w-100 custom-fc">
                                  <Typography component="label" mr={2}>
                                    No of Days
                                  </Typography>
                                  <OutlinedInput />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                md={4}
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <Typography component="p" className="small">
                                  After ___ Days an Alert will be Generated
                                  Saying the Item is Idle
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2} mt={1} mb={1}>
                              <Grid item md={4}>
                                <FormControlLabel
                                  className="custom-checkbox"
                                  control={<Checkbox />}
                                  label="LowStock"
                                />
                              </Grid>
                              <Grid item md={4}>
                                <FormControl className="w-100 custom-fc ">
                                  <Typography component="label" mr={2}>
                                    Threshold Value
                                  </Typography>
                                  <OutlinedInput />
                                </FormControl>
                              </Grid>
                              <Grid item md={4}>
                                <Typography component="p" className="small">
                                  After Stock Comes Below ___ then an Alert will
                                  be Generated Saying low stock into reports
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container>
                            <Box className="flex-between w-100" mt={2}>
                              <Typography className="font-bold" component="h4">
                                PRODUCT DETAILS
                              </Typography>
                              <Typography component="p" className="ag-skuid">
                                BEV-TE-FSD-FF-23LI
                              </Typography>
                              <Button
                                variant="outlined"
                                mt={2}
                                mb={2}
                                className="retail-btn"
                                mr={2}
                                onClick={() => ref4.current.log()}
                              >
                                Add Product
                              </Button>
                            </Box>
                            <Box className="member-table w-100">
                              <MemberTable
                                columns={memeberColumns}
                                options={options}
                                data={data}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <StoreNewItemModal ref={ref3} />
        <AddProductModal ref={ref4} />
      </Box>
    </>
  );
}
