import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardActions from "@mui/material/CardActions";
import SearchBar from "material-ui-search-bar";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CardMedia from "@mui/material/CardMedia";
import customers from "../images/customers.svg";
import mapmarker from "../images/map-marker.png";
import logout from "../images/logout.svg";
import p2 from "../images/p2.png";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import shoppingcounter from "../images/shopping-counter.png";

import logo from "../images/logo-white.png";
import ClearIcon from "../images/clear-cross.svg";
import pr0 from "../images/p1.png";
import pr1 from "../images/item1.png";
import pr2 from "../images/item2.png";
import pr3 from "../images/item3.png";
import pr4 from "../images/item4.png";
import pr5 from "../images/item5.jpg";
import pr6 from "../images/item6.jpg";
import pr7 from "../images/item7.jpg";
import item1 from "../images/item1.png";
import item2 from "../images/item2.png";
import item3 from "../images/item3.png";
import item4 from "../images/item4.png";
import item5 from "../images/item9.png";
import item6 from "../images/item10.png";
import shopimg from "../images/shopimg.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import globe from "../images/globe.png";
import cart from "../images/cart.svg";
import notification from "../images/notification.png";
import profile from "../images/user.png";
import star from "../images/star.png";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DistributorDetails() {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);
  const handleClick = (event, newValue) => {
    setValue(newValue);
  };
  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const [value, setValue] = React.useState(1);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main" className="main-section">
        <Grid container>
          <Grid item xs="12">
            {/* <Card xs="11" className="custom_card"> */}
            <Box className="w-100 text-center h-50">
              <Typography component="p" textAlign={"center"}>
                Shop Wholesale online from 85,000 brands
              </Typography>
            </Box>
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />
                  <Box>
                    {/* <Typography component="h2">POS {pos}</Typography> */}
                    <SearchBar
                      className="search-bar w-100"
                      placeholder="Search Products or Brands"
                    />
                  </Box>
                  <Box className="icons bredcum-icons">
                    <Box
                      component="img"
                      src={globe}
                      onClick={() => ref.current.log()}
                    />

                    <Typography component="h5" ml={1}>
                      EN-GB |
                    </Typography>
                    <ShoppingCartIcon />
                    <NotificationsIcon />

                    <PersonIcon onClick={() => ref.current.log()} />
                    {/* <Button variant="filled" className="invite-supplier-btn">
                      INVITE SUPPLIER
                    </Button> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box padding={5}>
              <Grid container spacing={2}>
                {/* <Grid item md={6}>
                  <Grid container>
                    <Grid item md={12}>
                      <Typography variant="p" className="detail-heading">
                        Sri Srinivasa Merchant
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item md={12}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box
                          component="img"
                          className="loc-img"
                          src={mapmarker}
                        />
                        <Typography
                          variant="p"
                          ml={1}
                          className="detail-location"
                        >
                          Hyderabad
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item md="12">
                      <Box display={"flex"}>
                        <Box className="tag-badge">
                          <Typography className="tags">
                            Appliances & Electronics
                          </Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">Bakeware</Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">
                            Cookware & Non Stick
                          </Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">
                            Cockery & Cutlery
                          </Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">
                            Flask & Caserole
                          </Typography>
                        </Box>
                        <Box className="tag-badge">
                          <Typography className="tags">Gardening</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item md="12">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordian-header"
                        >
                          <Typography>Ratings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" className="accordion-text">
                            Mr. Nihalchand wanted to try his luck in the family
                            profession – business. He started small to sell
                            household provisions in 1952, and named it as
                            ‘Karachiwala Stores’ In Visakhapatnam.
                          </Typography>
                          <Typography
                            variant="p"
                            mt={1}
                            className="accordion-text"
                          >
                            His business acumen coupled with hard work started
                            paying off, and it soon became a big hit. The main
                            reason for its success was that he could identify
                            the potential existing in the area of provisions for
                            North Indians and others.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordian-header"
                        >
                          <Typography>Merchant Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="p" className="accordion-text">
                            Mr. Nihalchand wanted to try his luck in the family
                            profession – business. He started small to sell
                            household provisions in 1952, and named it as
                            ‘Karachiwala Stores’ In Visakhapatnam.
                          </Typography>
                          <Typography
                            variant="p"
                            mt={1}
                            className="accordion-text"
                          >
                            His business acumen coupled with hard work started
                            paying off, and it soon became a big hit. The main
                            reason for its success was that he could identify
                            the potential existing in the area of provisions for
                            North Indians and others.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Box component="img" className="w-100" src={shopimg} />
                </Grid> */}
                <Grid item md={12}>
                  {/* <Box component="img" className="w-100" src={shopimg} /> */}
                  <Box className="distributor-img-detail"></Box>
                </Grid>
              </Grid>
              <Box padding={5}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <Typography component="h1" className="merchant-name-head">
                      Sri Srinivasa Merchant
                    </Typography>
                    <Typography component="p" className="about-merchant">
                      Shop Sri Srinivasa Vegatables and other independent brands
                      on the Bazaar wholesale marketplace.
                    </Typography>
                    <Button className="btn-outline-primary btn-space w-100 signup-btn-detail">
                      Sign Up to Shop
                    </Button>
                  </Grid>
                  <Grid item md={9}>
                    <Box>
                      <Tabs
                        value={value}
                        onChange={handleClick}
                        aria-label="basic tabs example"
                        className="custom-tab border-bottom"
                      >
                        {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                        <Tab label="All Products" {...a11yProps(0)} />
                        <Tab label="Organic" {...a11yProps(1)} />
                        <Tab label="Leafy" {...a11yProps(2)} />
                        <Tab label="Exotic" {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    <Box mt={1}>
                      <TabPanel value={value} index={0}>
                        <Grid container spacing={2}>
                          <Grid item md={3}>
                            <a href="/procurement/ProductViewNew">
                              <Box className="product-block">
                                <Box
                                  className="product-block-inner"
                                  textAlign={"center"}
                                >
                                  <Box
                                    component="img"
                                    src={item1}
                                    className="item-img"
                                  />
                                </Box>
                                <Box
                                  className="product-block-weight"
                                  textAlign={"center"}
                                >
                                  {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                                </Box>
                                <Box className="product-block-title-price">
                                  <a className="title" href="#">
                                    NEW POTATOES
                                  </a>
                                  <div className="reviews mb-2">
                                    <Box component="img" src={star} />
                                    <Box component="img" src={star} />
                                    <Box component="img" src={star} />
                                    <Box component="img" src={star} />
                                    <Box component="img" src={star} />
                                  </div>
                                  <div className="price mb-2">
                                    <span class="from">MRP </span>

                                    <span class="amount theme-money">₹25</span>
                                  </div>
                                  {/* <Box className="border-top pt-2 product-item-merchant">
                                  <a
                                    href="/procurement/DistributorDetailsNew"
                                    className="merchant-name"
                                  >
                                    Sri Srinivasa Vegetables
                                  </a>
                                </Box> */}
                                </Box>
                              </Box>
                            </a>
                          </Grid>
                          <Grid item md={3}>
                            <Box className="product-block">
                              <Box
                                className="product-block-inner"
                                textAlign={"center"}
                              >
                                <Box
                                  component="img"
                                  src={item2}
                                  className="item-img"
                                />
                              </Box>
                              <Box
                                className="product-block-weight"
                                textAlign={"center"}
                              >
                                {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                              </Box>
                              <Box className="product-block-title-price">
                                <a className="title" href="#">
                                  BRUSSEL SPROUTS
                                </a>
                                <div className="reviews mb-2">
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                </div>
                                <div className="price mb-2">
                                  <span class="from">MRP </span>

                                  <span class="amount theme-money">₹25</span>
                                </div>
                                {/* <Box className="border-top pt-2 product-item-merchant">
                                  <a
                                    href="/procurement/DistributorDetailsNew"
                                    className="merchant-name"
                                  >
                                    Fresh Fruits & Vegetables
                                  </a>
                                </Box> */}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item md={3}>
                            <Box className="product-block">
                              <Box
                                className="product-block-inner"
                                textAlign={"center"}
                              >
                                <Box
                                  component="img"
                                  src={item3}
                                  className="item-img"
                                />
                              </Box>
                              <Box
                                className="product-block-weight"
                                textAlign={"center"}
                              >
                                {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                              </Box>
                              <Box className="product-block-title-price">
                                <a className="title" href="#">
                                  ROYAL GALA APPLES
                                </a>
                                <div className="reviews mb-2">
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                </div>
                                <div className="price mb-2">
                                  <span class="from">MRP </span>

                                  <span class="amount theme-money">₹25</span>
                                </div>
                                {/* <Box className="border-top pt-2 product-item-merchant">
                                  <a
                                    href="/procurement/DistributorDetailsNew"
                                    className="merchant-name"
                                  >
                                    Organic Vegetables
                                  </a>
                                </Box> */}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item md={3}>
                            <Box className="product-block">
                              <Box
                                className="product-block-inner"
                                textAlign={"center"}
                              >
                                <Box
                                  component="img"
                                  src={item4}
                                  className="item-img"
                                />
                              </Box>
                              <Box
                                className="product-block-weight"
                                textAlign={"center"}
                              >
                                {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                              </Box>
                              <Box className="product-block-title-price">
                                <a className="title" href="#">
                                  FENNEL BULB
                                </a>
                                <div className="reviews mb-2">
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                </div>
                                <div className="price mb-2">
                                  <span class="from">MRP </span>

                                  <span class="amount theme-money">₹25</span>
                                </div>
                                {/* <Box className="border-top pt-2 product-item-merchant">
                                  <a
                                    href="/procurement/DistributorDetailsNew"
                                    className="merchant-name"
                                  >
                                    MVP Exotic Veggies
                                  </a>
                                </Box> */}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                          <Grid item md={3}>
                            <Box className="product-block">
                              <Box
                                className="product-block-inner"
                                textAlign={"center"}
                              >
                                <Box
                                  component="img"
                                  src={item5}
                                  className="item-img"
                                />
                              </Box>
                              <Box
                                className="product-block-weight"
                                textAlign={"center"}
                              >
                                {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                              </Box>
                              <Box className="product-block-title-price">
                                <a className="title" href="#">
                                  VINE TOMATOES
                                </a>
                                <div className="reviews mb-2">
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                </div>
                                <div className="price mb-2">
                                  <span class="from">MRP </span>

                                  <span class="amount theme-money">₹25</span>
                                </div>
                                {/* <Box className="border-top pt-2 product-item-merchant">
                                  <a
                                    href="/procurement/DistributorDetailsNew"
                                    className="merchant-name"
                                  >
                                    SKML Fruits & Vegetables
                                  </a>
                                </Box> */}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item md={3}>
                            <Box className="product-block">
                              <Box
                                className="product-block-inner"
                                textAlign={"center"}
                              >
                                <Box
                                  component="img"
                                  src={item6}
                                  className="item-img"
                                  height="140"
                                />
                              </Box>
                              <Box
                                className="product-block-weight"
                                textAlign={"center"}
                              >
                                {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                              </Box>
                              <Box className="product-block-title-price">
                                <a className="title" href="#">
                                  NECTARINES
                                </a>
                                <div className="reviews mb-2">
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                  <Box component="img" src={star} />
                                </div>
                                <div className="price mb-2">
                                  <span class="from">MRP </span>

                                  <span class="amount theme-money">₹25</span>
                                </div>
                                {/* <Box className="border-top pt-2 product-item-merchant">
                              <a
                                href="/procurement/DistributorDetailsNew"
                                className="merchant-name"
                              >
                                Sri Srinivasa Vegetables
                              </a>
                            </Box> */}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        2
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        3
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        4
                      </TabPanel>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}
